import { Routes, Route } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Message from './Message';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Routes>
          <Route path="/uid-not-found" element={<Message messageText="UID not found" />} />
          <Route path="/asset-not-found" element={<Message messageText="Not on blockchain" />} />
          <Route path="/meta-not-found" element={<Message messageText="UID not registered" />} />
          <Route path="/redirect-not-found" element={<Message messageText="No redirect found" />} />
          <Route path="/gated-content-locked" element={<Message messageText="Gated content locked" />} />
          <Route path="/error" element={<Message messageText="Interesting, an error occurred." />} />
          <Route path="/" element={<Message messageText="Discover" />} />
        </Routes>
      </header>
    </div>
  );
}

export default App;
